import { deleteRequest, getRequest, postRequest, putRequest } from '..';

export function add(attachmentType, entityId, title, base64FileString) {
  return postRequest(
    `/attachment/${attachmentType}/${entityId}`, 
    { title, attachmentData: base64FileString }
  );
}

export function edit(attachmentId, title) {
  return putRequest(`/attachment/${attachmentId}`, { id: attachmentId, title })
}

export function get(attachmentType, entityId) {
  return getRequest(`/attachment/${attachmentType}/${entityId}`);
}

export function remove(attachmentId) {
  return deleteRequest(`/attachment/${attachmentId}`);
}