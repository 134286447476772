<template>
  <div class="d-flex justify-content-between border-bottom mb-2 pb-1">
    <p 
      class="filename d-flex align-items-center mb-0 w-100"
      style="minHeight: 40px"
      >
      <span 
        v-if="isInViewMode"
        @click="enterEditMode"
      >
        {{ filename }}
      </span>
      <MaterialInput 
        v-show="isInEditMode"
        class="me-5"
        v-model="filenameHandler"
        type="text"
      />
    </p>

    <a 
      v-if="isExistingFile"
      class="btn btn-sm btn-primary mb-0 me-1 d-flex align-items-center"
      :href="attachment"
      title="Openen in nieuw tabblad"
      target="_blank"
    >
      <span 
        class="material-icons"
      >
        open_in_new
      </span>
    </a>

    <button
      class="btn btn-sm btn-primary mb-0 d-flex align-items-center"
      :title="removeButtonTitle"
      @click="removeAttachment"
      >
        <span 
          class="material-icons"
        >
          {{ removeButtonIcon }}
        </span>
    </button>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue"

import  { createVModelHandlers } from "@/helpers/vue"

import { remove } from "@/api/providers/attachment"

const Modes = {
    EDIT: 0,
    VIEW: 1
}

export default {
  name: 'FileUploadItem',

  components: {
    MaterialInput
  },

  props: {
    id: { type: Number, default: 0 },
    filename: { type: String, required: true },
    attachment: { type: String, required: true }
  },

  emits: [
    'update:filename',
    'delete-file'
  ],

  data() {
    return {
      mode: Modes.VIEW
    }
  },

  computed: {
    removeButtonTitle() { return this.isExistingFile ? "Bestaand item verwijderen" : "Upload anulleren" },
    removeButtonIcon() { return this.isExistingFile ? "delete" : "close" }, 
    isInViewMode() { return this.mode === Modes.VIEW },
    isInEditMode() { return this.mode === Modes.EDIT },
    isExistingFile() { return this.id > 0 },
    ...createVModelHandlers(
        'filename'
    ),

  },

  methods: {
    enterEditMode(e) { 
      e.preventDefault();
      this.mode = Modes.EDIT
    },
    
    enterViewMode(e) { 
      e.preventDefault();
      this.mode = Modes.VIEW
    },

    removeAttachment(e) {
      e.preventDefault();
      // If it's an attachment that's already been uploaded, confirm before removal
      if(this.id > 0) {
        this.$swal({
          title: 'Weet u zeker dat u dit bestand wilt verwijderen?',
          showCancelButton: true,
          confirmButtonText: 'Ja',
          cancelButtonText: 'Nee',
        }).then(() => {
          remove(this.id)
          this.$emit('delete-file', { id: this.id, filename: this.filename })
        })  
        return;
      }
      this.$emit('delete-file', { id: this.id, filename: this.filename })
    }
  }
}
</script>