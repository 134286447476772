<template>
  <h5>{{ title }}</h5>
  <div 
    v-if="filesArePresent" 
    class="file-container"
  >
    <FileUploadItem 
      v-for="file in files"
      :id="file.id" 
      :key="file.filename" 
      v-model:filename="file.title"
      :attachment="file.attachment" 
      @delete-file="removeFile" 
    />
  </div>
  <p 
    v-if="!filesArePresent"
  >
    Nog geen bestanden geüpload
  </p>
  <input 
    id="file-upload" 
    class="d-none" 
    type="file" 
    accept=".pdf" 
    multiple 
    @change="handleLocalFileUpload"
  />
  <label 
    class="btn btn-primary" 
    for="file-upload"
  >
    Upload PDF-bestanden
  </label>
</template>

<script>
import FileUploadItem from './FileUploadItem.vue';

import { get } from "@/api/providers/attachment"

import { createVModelHandlers } from "@/helpers/vue"

export default {
  name: 'FileUpload',
  components: { FileUploadItem },
  props: {
    title: { type: String, default: 'Bijlagen' },
    files: { type: Array, default: () => [] },
    attachmentType: { type: Number, required: true }, // The type of attachment which the files belong to. Refer to API documentation for a full list of options.
    entityId: { type: Number, required: true } // The ID of the entity to which the files belong.
  },

  emits: [
    'update:files'
  ],

  computed: {
    filesArePresent() { return this.files.length > 0 },
    ...createVModelHandlers(
      'files'
    ),

    computedFiles: {
      get() { return this.files },
      set(value) { this.$emit('update:files', value) }
    }
  },

  mounted() {
    this.loadFiles()
  },

  methods: {
    addFile(id, filename, data) {
      let files = this.computedFiles;
      files.push({ id, title: filename, data });
      this.computedFiles = files
    },

    removeFile(data) {
      this.computedFiles = this.computedFiles.filter(item =>
        item.title !== data.filename ||
        item.id !== data.id
      )
    },

    loadLocalFile(file) {
      var reader = new FileReader();
      reader.onload = () => this.addFile(0, file.name, reader.result)

      reader.readAsDataURL(file)
    },

    handleLocalFileUpload(event) {
      for (const file of event.target.files) {
        this.loadLocalFile(file)
      }
    },

    loadFiles() {
      if (this.entityId === 0) return

      get(this.attachmentType, this.entityId)
        .then(data => {
          if (data !== undefined) {
            this.computedFiles = data
          }
        })
    }
  },
}
</script>